import { Component, OnInit, NgZone, ViewChild, Renderer2, 
  ElementRef} from '@angular/core';
  import { Location } from "@angular/common";
import { Router, ActivatedRoute,NavigationStart,NavigationEnd   } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import {CartService} from '../../../../Lazy-loaded-Modules/Cart-module/cart-page/cart-page.service';
import { UntypedFormGroup, UntypedFormBuilder,Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { AppService } from 'src/app/app.service';
// import { HeaderService } from '../Header/header.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import {HeaderService} from './header.service';
import {config} from 'src/configs/config';
import {HostListener } from '@angular/core';
import { AlertsService } from "src/app/Components/alerts/alerts.service";
import { CheckoutLocalStorageService as CheckoutLocalStorageServiceNew } from 'src/app/Lazy-loaded-Modules/Checkout-new/checkout-local-storage.service';
import { LoginService } from 'src/app/Lazy-loaded-Modules/Account-Modules/login-new/login-new.service';
import { ProductListingService } from '../../../../Lazy-loaded-Modules/Product-Modules/product-lists/product-listing/product-listing.service';
import { SEOService } from 'src/app/seo.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-two-header',
  templateUrl: './header-two.component.html'
})
export class HeaderTwoComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: ElementRef;
  @ViewChild('targetSection') targetSection!: ElementRef;
  searchForm: UntypedFormGroup;
  preList = [];
  totalPrice = 0;
  tab: number = 1;
  userLoggedIn: boolean = false;
  isSearch: boolean = false;
  productOrList: boolean;
  currentPageUrl;
  sendquery_vimpro: UntypedFormGroup;
  productId: any;
  searchTerm: any;
  allCategories: any;
  logoImage : string;
  public discountValue;
  accelbanner:any;
  configs = config;
  reqData1;
  promotionBanner: any;
  fruits:any='fruits'
  skincare:any='skincare'
  plants:any='plants'
  allcategories:any='allcategories'
  vegetablesres:any='vegetablesres'
  othersres:any='othersres'
  fruitsres:any='fruitsres'
  vegetables:any='vegetables';
  Courses:any='Courses';
  others:any='others'
  pages:any='pages'
  clepages:any='clepages'
  routeurl;
  showAlerts = true;
  public outletId;
  existingCartProduts = [];
  prices1: number[] = [0, 650];
  selectedRange: any;
  countryList =['India','Malaysia', 'Japan'];
  subRanges: string[] = [];
  shouldRemoveSticky: boolean = false; 
  enablecolor: boolean = false; 
  subTotal=0;
  private subscriptions: Subscription = new Subscription();
  dropdownOpen = false;
  submenuOpen = false;
  subSubmenuOpen = false; 
  dropdownOpen1 = false;
  dropdownOpen2 = false;
  dropdownOpen3 = false;
  dropdownOpen4 = false;
  dropdownOpen5 = false;
  subsubcategory = false;
  submenuOpen1 = false;
  subSubmenuOpen1 = false; 
  subcategory1:any;
  subcategory2:any;
  subcategory3:any;
  subcategory4:any;
  subCategoryData:any;
  deeracolor=[];
  deeradimension=[];
  active = 'dontcheck';
  active1 = '';
  active2='';
  activeSubCategory="";
  cate:any;
  // shouldRemoveSticky: boolean = false; 
  currentUrl;
  @ViewChild('dropdownMenuButton') dropdownMenu: ElementRef;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFunction() {
    if(this.configs.title=='Vimpro'){
      var mybutton = document.getElementById("vimpro_cate");
    
    if (window.pageYOffset< 0 ) {
      
      mybutton.style.display = "block";
    } 
    if(window.pageYOffset== 0 ){
      mybutton.style.display = "block";

    }
    else {
      mybutton.style.display = "none";
    }
      
    }

    
  }

  resultMessage: any;
  constructor(
    config1: NgbDropdownConfig,
    private location: Location,
    // private authService: AuthTokenService,
    private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private headerService: HeaderService,
    private alertService: AlertsService,
    private LoginService:LoginService,
    private checkoutLocalStorageService1: CheckoutLocalStorageServiceNew,
    private renderer: Renderer2,
    private listingService: ProductListingService,
    private seoService: SEOService,

  ) {
    if(this.configs.title!='LinenRaj'){
    
      config1.placement = 'bottom-right'
      this.location.subscribe(location => {
        // ...close popup
        this.closeModal.nativeElement.click();
        this.validateForm=false;
      });

    }
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: any) => {
      //console.log(event.url,"event.url123")
      this.route.url=event.url; 
      this.routeurl=event.url;
      this.cartService.getURL(this.routeurl);
      console.log("routeurltest",this.routeurl)
      console.log("this.route.urllllllllllllllllllllllllllllllllllll",this.appService.outletId)
      console.log("this.route.ukkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",this.localService.get('karupattirajaOutletId'))
      this.outletId = this.appService.outletId;
      if(this.outletId==1){
        this.outletId = this.localService.get('karupattirajaOutletId');
      }
      if(this.configs.title=="Karupattiraja" || this.configs.title=="Deera"){
        if(this.outletId=="0"|| this.outletId==0||this.outletId==""|| this.outletId==null){
          this.outletId=1;
        }
        
        
      }
      if(this.configs.title=="KatbookStore"){
        const url = this.currentUrl;
  
  // Create a URL object
         const parsedUrl = new URL(url);
  
  // Get the pathname of the URL
        const pathname = parsedUrl.pathname;
  
  // Split the pathname by '/'
         const pathParts = pathname.split('/');
  
  // Get the third term
        const thirdTerm = pathParts[3];
  
        console.log(thirdTerm,"thirdTerm");
  
       }
      console.log(this.route.url,"this.route.url",this.outletId)
      console.log(this.route.url,"this.route.url",this.appService.outletId)
     })
   
   
  }
 
  ngOnInit(): void {
    this.currentPageUrl = this.getCurrentURL()
    this.seoService.updateCanonicalUrl(this.currentPageUrl);

if(this.configs.title=="ShubhCards"){
  this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe((event: NavigationEnd) => {
    console.log('URL changed to: ' + event.urlAfterRedirects);
    if(event.urlAfterRedirects=='/'){
      this.enablecolor=true;
    }
    else{
      this.enablecolor=false;
    

    }
    // You can handle the URL change here
  });

}
   
    console.log(this.configs.title,"this.configs.title",window.location.href)
   
    this.currentUrl = (window.location.href);
    // .split('?')[0];
    if(this.configs.title=="Accel"){
      this.renderer.listen('document', 'click', (event: Event) => {
        if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
          this.openacceldropdownclose();
          //document.getElementById("dropdownMenuButton").click()
        }
      });

    }
    this.outletId = this.appService.outletId;
    if(this.outletId==1){
      this.outletId = this.localService.get('karupattirajaOutletId');
    }
    console.log( this.outletId," this.outletId",this.appService.outletId)
    if(this.configs.title=="Karupattiraja"){
      if(this.outletId=="0"|| this.outletId==0||this.outletId==""|| this.outletId==null){
        this.outletId=1;
      }
      
      
    }
    
   console.log("AAAAAAAAAAAAAA",this.appService)  
   
    // this.subscriptions.add(
    //   this.appService.cartProducts$.subscribe(products => {
    //     this.calculateSubtotal(products);
    //   })
    // );
    console.log("calculated total",this.subTotal)
    this.routeurl = this.router.routerState.snapshot.url;
    console.log( this.routeurl,this.router,"this.routeurl",(window.location.href))
    // this.cartService.totalCartItems();
    // this.appService.cartProducts.results = this.cartService.getCartProducts();

    
   // console.log("routeurl",currentUrl)
    console.log("userLoggedIn"+this.userLoggedIn);
    this.userLoggedIn = this.appService.loggedin_user;
    this.searchForm = new UntypedFormGroup({
      search_term: this.formBuilder.control('')
    })
    this.sendquery_vimpro = new UntypedFormGroup({
      customer_name: this.formBuilder.control("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z\s\W]+$/)
      ]),
      customer_mobile: this.formBuilder.control("", [
        Validators.required,
        Validators.max(9999999999), Validators.min(1000000000)
      ]),
      customer_email: this.formBuilder.control("", [
        Validators.required,
        Validators.pattern(/^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i)
      ]),
      company_name: this.formBuilder.control(""),
      queries: this.formBuilder.control("", [Validators.required,Validators.maxLength(600)])
    });

    this.headerService.currentData.subscribe(
      currentData => {
        let curData:any = currentData;
        console.log("curData.name"+curData.name)
        if(curData.name === "subTotal"){
          let tempArray = this.appService.cartProducts.results;
          tempArray.forEach((item)=>
          {
            // this.subTotal = this.subTotal + (item.cost * item.quantity)
            this.subTotal = (item.cost * item.quantity)
          })
        }
      }
    )

    this.getAllCategories();

    this.getLogo();
  
    this.getPromotionBanner();
    // if (this.authService.getUserName() != 'undefined' && this.authService.getUserName()) {
    //   //The front-end should change when this is called.
    //   this.appService.userLoggedIn = true;
    //   //this.appService.userName = this.authService.getUserName();
    // }
    // else {
    //   this.appService.userLoggedIn = false;
    //   this.appService.userName = "";
    // }

    //TO CLEAR THE SEARCH TERM FROM OTHER EVENTS
    this.headerService.currentData.subscribe(
      currentData =>{
        if(currentData === 'clearSearchTerm'){
          this.closePrelist();
        }
      }
    )
    this.splitRange()
  }
  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    // This runs after the view is initialized
    this.route.fragment.subscribe(fragment => {
      if (fragment && this.targetSection) {
        this.scrollToSection(fragment);
      }
    });
  }
  openacceldropdown(){
    console.log("1232345")
    document.getElementById("dropdown").classList.toggle("show");
  }
  openacceldropdownclose(){
    console.log("1232345")
    const dropdown = document.getElementById("dropdown");
    dropdown.classList.remove("show")
    //document.getElementById("dropdown").classList.toggle("");
  }

  getPreList(){
    let searchData = this.searchForm.value.search_term;
    if(searchData.length >=2){
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        // "outlet_id":Number(this.outletId),
        // "outlet_id":"",
        "extras": {
          "find": {
            // "outlet_id":this.outletId,
            "search": searchData
          }
        }
      }
      if(this.configs.title === 'SriSenbaga'){
        this.appService.postApiCall(reqData, ENDPOINTS.GET_TAMIL_SEARCH).subscribe(
          resp => {
            this.preList = resp.result.data;
            
          },
          err => {
            if (err) {
              console.log("Unable to get prelist data: ", err.message)
            }
            else{
              console.log('Something bad happened; Please try again!')
            }
          })
      }
      else{
      this.appService.postApiCall(reqData, ENDPOINTS.GET_PRELIST).subscribe(
        resp => {
          this.preList = resp.result.data;
          
        },
        err => {
          if (err) {
            console.log("Unable to get prelist data: ", err.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
      }
    }
    
    else{
      this.preList = [];
    }
  }
  get f_vimpro() {
    if(this.configs.title === "Vimpro"){
    return this.sendquery_vimpro.controls;
    }
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  validateForm=false;
  close_vimpro_model()

  {
    this.validateForm=false;
    this.sendquery_vimpro.reset();
    this.closeModal.nativeElement.click();
    
  }
  saveForm_vimpro() {
   
    // console.log(this.sendquery_vimpro.value.customer_email)
    // console.log(this.sendquery_vimpro.value.customer_name)
    // console.log(this.sendquery_vimpro.value.customer_mobile)
    // console.log(this.sendquery_vimpro.value.queries)
    // console.log(String(this.sendquery_vimpro.value.customer_mobile).length)
    var emailpattern=/^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
    var namepattern=/^[a-zA-Z\s\W]+$/;
    if (this.sendquery_vimpro.value.customer_email === ""||this.sendquery_vimpro.value.customer_email === null||!emailpattern.test(this.sendquery_vimpro.value.customer_email)) {
      this.validateForm = true;
    } else if (this.sendquery_vimpro.value.customer_mobile === ""||this.sendquery_vimpro.value.customer_mobile === null||String(this.sendquery_vimpro.value.customer_mobile).length !=10) {
      this.validateForm = true;
    } else if (!namepattern.test(this.sendquery_vimpro.value.customer_name)||this.sendquery_vimpro.value.customer_name === ""||this.sendquery_vimpro.value.customer_name===null) {
      this.validateForm = true;
    }
    else if (this.sendquery_vimpro.value.queries === ""||this.sendquery_vimpro.value.queries ===null) {
      this.validateForm = true;
    }
    else{
      this.validateForm = false;
      this.alertService.success("Query Submitted Successfully", this.options);
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        customer_name:this.sendquery_vimpro.value.customer_name,
        customer_mobile:this.sendquery_vimpro.value.customer_mobile,
        customer_email:this.sendquery_vimpro.value.customer_email,
        company_name:this.sendquery_vimpro.value.company_name,
        queries:this.sendquery_vimpro.value.queries,
      };
      this.closeModal.nativeElement.click();
      this.appService.postApiCall(requestData, ENDPOINTS.SENDVIMPRO_QUERY).subscribe(
        resp => {
          
          this.sendquery_vimpro.reset();
          
        },
        err => {
          if (err) {
            console.log("error in submitting form", err.message)
          }
          else {
            console.log('error in submitting form!')
          }
        })
  
    }
   

    
  }
  submitEnquiryForVimpro(){
    let userId = this.localService.get('UserId');
		 if(userId){
      var customerdata=JSON.parse(this.localService.get('CustomerData'));
      //console.log(customerdata)
      this.sendquery_vimpro.controls['customer_name'].setValue(customerdata.userName!=null?customerdata.userName:'' , {onlySelf: true});
      this.sendquery_vimpro.controls['customer_email'].setValue(customerdata.email!=null?customerdata.email:'' , {onlySelf: true});
      this.sendquery_vimpro.controls['customer_mobile'].setValue(customerdata.mobileNo!=null?customerdata.mobileNo:'' , {onlySelf: true});
		
			 }
        document.getElementById("sendquery-model-vimpro").click();
       
    }
  getPromotionBanner() {
    let respData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        }
      }
    }
    this.appService.postApiCall(respData, ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if(resp.message!="No records found"){
          this.promotionBanner=resp.result.data[0].eligible_banner_message
        }
      },
      err => {
        if (err) {
          console.log("Unable to get Promotion Banner: Error: ", err.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }


  hideBanner(){
    document.getElementById("banner").style.display="none"
  }

  searchSenbaga(val){
    this.searchProductDetails(val);
    document.getElementById("searchnav").style.width = "0%";
  }
  

  savePreList(val){
    this.localService.remove("option1")
    this.localService.remove("option2")
    let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience'||this.configs.title === 'Karupattiraja') {
    if(userId){
      this.sendProductDetails(val);
     }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   else{
    if(this.configs.title=="kubendran" || this.configs.title=="AvonSeals" || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" || this.configs.title=="Cle"|| this.configs.title=="BestGifts" || this.configs.title=="LinenRaj" || this.configs.title=="Deera" || this.configs.title=="Babys Pride" || this.configs.title=="LushNGreen" || this.configs.title=="Taazah" || this.configs.title=="PaulCaroline"){
      document.getElementById("searchnav").style.width = "0%";
      var navbar = document.getElementById("myfruits")
      if(navbar!=undefined && this.configs.title=="BestGifts" ){
        console.log(navbar,"navbar")
        document.getElementById("myfruits").style.width = "0%"

      }


    }
    this.sendProductDetails(val);
  
    
   }
    //document.getElementById("searchnav").style.width = "0";
    
  }

  savePreList1(){
    if (this.configs.title === 'Carience'||this.configs.title === 'Karupattiraja') {
      let userId = this.localService.get('UserId');
      if(userId){
        var searchVal1 = this.searchForm.controls['search_term'].value;
    if(searchVal1){
      let searchData = {
        "name": searchVal1,
        "type": ""
    }
      this.sendProductDetails(searchData);
    }
       }
      else{
        this.LoginService.updateAdminFormEvent({name: "ask_login"});
        this.logIn();
       // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
      }
     }




    
  }

  logIn(){
    if(this.configs.title === "Karupattiraja")
    {
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.router.navigate([`/login-new`], { skipLocationChange: false });
    }
    else if(this.configs.title === "Accel"){
      if(this.appService.userLoggedIn){
        this.router.navigate([`/my-account`], { skipLocationChange: false });

      }
      else{
        this.router.navigate([`/login-new`], { skipLocationChange: false });

      }

    }
    else{
      if(this.configs.title === "Schift Cosmetics"){
        document.getElementById("mySidenav").style.width = "0";
      }
      this.router.navigate([`/login-new`], { skipLocationChange: false });

    }
   
   
    //this.router.navigate([`/login-new`], { skipLocationChange: false });

  }

  myAccount(){
    if(this.configs.title === "Schift Cosmetics"){
      document.getElementById("mySidenav").style.width = "0";
    }
    this.router.navigate([`/my-account`], { skipLocationChange: false });

  }
  shenbagamlocation(){
    window.location.href = "https://www.google.com/maps/place/Sri+Senbaga+Pathippakam+Books/@13.0389813,80.2403744,17z/data=!3m1!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x880fb703fd279f01:0xcfef5718a7ca2ef3!2sNew+No+32B,+Krishna+Street,+Pondy+Bazaar,+T+Nagar,+Chennai,+Tamil+Nadu+600017!2m2!1d80.2429493!2d13.0389813!3m5!1s0x880fb703fd279f01:0xcfef5718a7ca2ef3!8m2!3d13.0389813!4d80.2429493!16s%2Fg%2F11b7xq0v7r?entry=ttu"
  }
  myOrder(){
    console.log("userIdddddddd")
    let userId = this.localService.get('UserId');
    if(userId){
      console.log("userId"+userId)
      window.scroll(0,0)
      // this.router.navigate(['/my-orders'])
      this.router.navigate(['/my-orders'], { queryParams: { url:'contact-us' },relativeTo:this.route,  skipLocationChange: false })

    } else {
      this.router.navigate(['/login-new'], { relativeTo:this.route,  skipLocationChange: false })
    }

  }
  

  logOut() {
    
    this.appService.userLoggedIn = false;
    this.appService.userName = "";
    console.log( this.appService.userName,"login user")

    this.appService.loggedin_user = false;
    this.userLoggedIn = this.appService.loggedin_user;
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('customerGroup');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.localService.remove('AiemaUserName');
    this.localService.remove('couponCode');
    this.localService.remove('company_name');
    this.localService.remove('gst_number');
    this.localService.remove('company_address');
    this.localService.remove('karupattirajaOutletId');
    // this.ngOnInit();
    // this.cartService.clearCart();
    //this.loggedIn = false;
    this.router.navigate(["/"]);
    this.checkoutLocalStorageService1.clearDataonLogout();
    this.checkoutLocalStorageService1.removeOrderId();
    document.getElementById('cancel_btn').setAttribute('data-dismiss','modal');
    document.getElementById('closemodal').click();

    if(config.title=='Karupattiraja' || config.title=='Carience'||config.title=='SpecialWires'||config.title=='LinenRaj'||config.title=='Mayan'||config.title=='Schift Cosmetics'||config.title=='LushNGreen' || this.configs.title== 'Taazah' || this.configs.title== 'PaulCaroline')
    {
      this.appService.removeSession();
    }
    if(config.title=='Karupattiraja'){
      this.appService.outletId="1";
      this.outletId="1";
      // window.location.reload()
    }

    if(this.configs.title === "Schift Cosmetics"){
      document.getElementById("mySidenav").style.width = "0";
    }
    // window.location.reload()
    if(window.location.href=="https://karupattiraja.ecbee.net/#/" || window.location.href=="https://karupattiraja.ecbee.net"|| window.location.href=="http://localhost:4200/#/" || window.location.href=="https://karupattiraja.ecbee.net" || window.location.href=="https://karupattiraja.ecbee.net/#/"){
      // console.log("124578963")
      this.localService.set('karupattirajaOutletId',"1");

      this.appService.outletId="1";
      this.outletId="1";
     window.location.reload()
      console.log(this.appService.outletId,this.outletId)
      

    }
    else{
      this.localService.set('karupattirajaOutletId',"1");

      this.appService.outletId="1";
      this.outletId="1";

    }
    
  }
  productDetails(id) {
    
 
      this.router.navigate(["/product-details", id], {
        relativeTo: this.route,
        skipLocationChange: false,
      });
    }
  
    productList(cate){
      this.headerService.updateAdminFormEvent('clearSearchTerm');
      if(config.title==='Accel') {
        this.router.navigate([`/productListing`], {queryParams: { terms:[[],[cate],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
       }
    }
    
    removeItem(index)
    {
      this.appService.cartProducts.results.splice(index,1);
      this.cartService.updateCartItem(this.appService.cartProducts.results);
    }

searchProductDetails(searchTerm){
  let searchRequestData = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "outlet_id":Number(this.outletId),
    // "outlet_id":"",
    "extras": {
      "find": {
        "search": searchTerm.name,
        // "outlet_id":this.outletId,
      },
      "pagination": true,
      "paginationDetails": {
        "limit": 1,
        "pagesize": 35
      },
      "sorting": true,
      "sortingDetails": {
        "sortfield": "id",
        "sortorder": 1
      }
    }
  }
  this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_TAMIL_SEARCH_RESULT).subscribe(
    resp => {
      if(searchTerm.type=='Brand'){
        this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Category'){
          this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Product Name'){
          this.router.navigate([`/product-details/${searchTerm.id}`]);
        }
  }
)
}



  sendProductDetails(searchTerm){
    console.log("searchTerm"+searchTerm)
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      // "outlet_id":Number(this.outletId),
      // "outlet_id":"",
      "extras": {
        "find": {
          "search": searchTerm.name,
          // "outlet_id":this.outletId,
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }
    // MAKE AN API CALL
     this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
       
        let respData = resp.result.data;
        this.preList = [];
        //DIRECT TO PRODUCT DETAILS IF ID PRESENT
        console.log("respData.length1"+respData.length)
        if(respData.length === 1){
          console.log("respData.length2"+respData.length)
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "Aiema" ||this.configs.title === "Cle"){
              this.router.navigate([`/company-details1/${respData[0].id}`]);
            }
            else{
              console.log("respData.length3"+respData.length)
              this.router.navigate([`/product-details/${respData[0].id}`]);
            }
            
          })
        }
        else if (respData.length > 1){
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
             else if(this.configs.title === "Beaubelle" ||this.configs.title === "Accel" || this.configs.title === "BombayHardware"||this.configs.title === "Vimpro"||this.configs.title === "SpecialWires" || this.configs.title=="Vistas" || this.configs.title=="BestGifts"|| this.configs.title=="Babys Pride"|| this.configs.title=="HOB"|| this.configs.title=="PaulCaroline"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if (searchTerm.type=='Tags'){
                    
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

              }
              else if(searchTerm.type=='Product Name'){
                this.router.navigate([`/product-details/${searchTerm.id}`]);
              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
                else if(this.configs.title=="Schift Cosmetics"){
                  if(searchTerm.type=='Category'){
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                  }
                  else if(searchTerm.type=='Product Name'){
                    this.router.navigate([`/product-details/${searchTerm.id}`]);
                  }
                }
                else if(this.configs.title=="Deera"){
                  if(searchTerm.type=='Brand'){
                  this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                console.log("BrandIn") 
                }
                  else if(searchTerm.type=='Category'){
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    console.log("CatIn") 
                  }
                  else if (searchTerm.type=='Tags'){
                        
                    this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
                    console.log("TagIn") 
                  }
                  else if(searchTerm.type=='Product Name'){
                    this.router.navigate([`/product-details/${searchTerm.id}`]);
                    console.log("ProdIn") 
                  }
                 // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    }
                else if(this.configs.title === "Karupattiraja"){
                  let userId = this.localService.get('UserId');
    if(userId){
      if(searchTerm.type=='Brand'){
        this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Category'){
          this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Product Name'){
          this.router.navigate([`/product-details/${searchTerm.id}`]);
        }
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   
                
                 // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    }
                    else if(this.configs.title === "KatbookStore"){
                     
        {
          if(searchTerm.type=='Brand'){
            this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
            }
            else if(searchTerm.type=='Category'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
            }
            else if(searchTerm.type=='Product Name'){
              this.router.navigate([`/product-details/${searchTerm.id}`]);
            }
        // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
          }
       
       
                    
                     // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                        }
                else if(this.configs.title === "Kubendran"){
                  if(searchTerm.type=='Brand')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
    
                  }
                  else if(searchTerm.type=='Category')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  else
                  {
                    
                    this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  
                }
             
            else if(this.configs.title=="Mayan"){
              if(searchTerm.type=="Category")
              this.router.navigate([`/product`], {
                queryParams: {
                  terms: [searchTerm.name, searchTerm.type],
                  type: "searchresults",
                },
                relativeTo: this.route,
                skipLocationChange: false,
              });
              
              else{
                this.router.navigate([`/product-details/${respData[0].id}`]);
              }

            } 
              
            //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else if (this.configs.title=="BombayHardware" || this.configs.title=="AvonSeals" || this.configs.title=="ShubhCards" || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" ){
              console.log("category",)
              if(searchTerm.type=='Category' && this.configs.title!='ShubhCards')
             
              {
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
               

              }
              if(this.configs.title=='ShubhCards' && searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

              }

              else
              {
                console.log("111")
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              }
            }
            else {
              console.log("222")
              if(this.configs.title=="Cle"){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

              } else {
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              }
              // this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
        else{
          this.searchForm.controls['search_term'].setValue(searchTerm.name);

          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type=="Category")){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type!=="Category")){
              this.router.navigate([`/product-details/${respData[0].id}`])
            } 
            else if(this.configs.title === "Beaubelle"||this.configs.title === "Vimpro"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
                else if(this.configs.title === "Accel"){
                  
                      if(searchTerm.type=='Category'){
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
    
                  }
                 // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    }
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else if(this.configs.title=="PaulCaroline" || this.configs.title=="HOB"){
              console.log("know the type",searchTerm.type)
              if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Product Name'){
                this.router.navigate([`/product-details/${searchTerm.id}`]);
              }
            }
            else {
              console.log("333")
              this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
      },
      err => {
        if (err) {
          console.log("Error with product details: ", err.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  sendProductDetails1(searchTerm){
    console.log("searchTerm"+searchTerm)
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }
    // MAKE AN API CALL
     this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        //DIRECT TO PRODUCT DETAILS IF ID PRESENT
        console.log("respData.length1"+respData.length)
        if(respData.length === 1){
          console.log("respData.length2"+respData.length)
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "Aiema" ||this.configs.title === "Cle"){
              this.router.navigate([`/company-details1/${respData[0].id}`]);
            }
            else{
              console.log("respData.length3"+respData.length)
              this.router.navigate([`/product-details/${respData[0].id}`]);
            }
            
          })
        }
      
        
               
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
           
          })
        }
      
     
  

  getAllCategories() {
    if(this.configs.title==='BombayHardware' || this.configs.title==='AvonSeals' || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" || this.configs.title=="Accel"){
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "sort_order",
            "sortorder": -1
          }
        }
      }
    } 
    else if(this.configs.title==='Vimpro'){
    
        this.reqData1 = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": null,
          "extras": {
            "find": {
            },
            "pagination": false,
            "paginationDetails": {
              "limit": 1,
              "pagesize": 25
            },
            "sorting": true,
            "sortingDetails": {
              "sortfield": "sort_order",
              "sortorder": -1
            }
          }
        }
      
    }
    else if(this.configs.title==='Carience'){
    
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "sort_order",
            "sortorder": -1
          }
        }
      }
    
  }
  else if(this.configs.title==='PaulCaroline'){
    
    this.reqData1 = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "sort_order",
          "sortorder": -1
        }
      }
    }
  
}
else if(this.configs.title==='HOB'){
    
  this.reqData1 = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras": {
      "find": {
      },
      "pagination": false,
      "paginationDetails": {
        "limit": 1,
        "pagesize": 25
      },
      "sorting": true,
      "sortingDetails": {
        "sortfield": "sort_order",
        "sortorder": -1
      }
    }
  }

}
    else {
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "outlet_id":Number(this.outletId),
        // "outlet_id":"",
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
    }
		this.appService.postApiCall(this.reqData1, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {
        if(this.configs.title === 'Deera'){
          this.allCategories = this.sortByKey(resp.result.data,'name');
          console.log('nnnnnnnnnnnnn'+this.allCategories.length);
         console.log('nnnnnnnnnnnnn'+resp.result.data[0].subcategories.length);
         console.log('ooooooooooooo'+resp.result.data[1].subcategories.length)
        //  console.log('rrrrrrrrrrrrr'+resp.result.data[2].subcategories.length)
         resp.result.data.forEach((item)=>
          { if(item.subcategories){
          if(item.name==="Disposable Cutlery"){
            this.subcategory1=item.subcategories
          }
          if(item.name==="Packaging"){
            console.log('mmmmmmmm');
            this.subcategory2=item.subcategories
          }
          }
          })
          // this.subcategory1=resp.result.data[0].subcategories;
          // this.subcategory2=resp.result.data[1].subcategories;
          // this.subcategory3=resp.result.data[2].subcategories;
          // this.subcategory4=resp.result.data[3].subcategories!=null?resp.result.data[3].subcategories:0;
        } 
        else if(this.configs.title === 'PaulCaroline' || this.configs.title === 'HOB'){
          resp.result.data.forEach((item)=>
            { if(item.subcategories){
            if(item.name==="Men"){
              this.subcategory1=item.subcategories
              console.log("subcat1",this.subcategory1)
            }
            if(item.name==="Women"){
              console.log('mmmmmmmm');
              this.subcategory2=item.subcategories
              console.log("subcat2",this.subcategory2)
            }
            }
            })
        }
        else {
          this.allCategories = resp.result.data;
        }
			},
			err => {
				if (err) {
					console.log(err)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

  getAllProductList(category){
    this.searchForm.controls['search_term'].setValue(category);
    this.router.navigate([`/productListing`], {queryParams: { terms:[category], type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
  }
  

  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source;
      this.accelbanner = respData.carousel.slides;
    },
    err => {
      if (err) {
        console.log("Unable to obtain logo \n, ERROR:", err.message);
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }
 openNavkub() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
    
   // document.getElementById("myothers").style.width = "0";
   if(this.configs.title!=='Accel'){
    document.getElementById("mySidenav").style.width = "60%";
   }
   if(this.configs.title==='Accel'){
    document.getElementById("Courses").style.width = "0";
   }
  }
  openNavkub1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    //document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  opensearchnavkub() {
    document.getElementById("searchnav").style.width = "100%";
    document.getElementById("mySidenav").style.width = "0";

  }
  closesearchnavkub() {
    document.getElementById("searchnav").style.width = "0";
    
  }
  closeNavkub1() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNavkub11() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }
  openNav() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
    document.getElementById("myothers").style.width = "0";
    document.getElementById("mySidenav").style.width = "60%";
  }
  openNav1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNav1() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }

  bestgifts(){
    this.productsList();
    this.closeNav();
  }

  search(){
    this.isSearch = true;
  }

  decreaseItem(index)
  {
    let tempArray = this.appService.cartProducts.results;
    if(this.configs.title==='ShubhCards')
  {

    if(this.appService.cartProducts.results[index].quantity- tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.loadExistingCart()
        return
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  else
  {

    console.log("App service products: ", this.appService.cartProducts.results)
    console.log("tempArray data: ", tempArray[index])
    if(tempArray[index].product.pricing !== undefined){
      if(this.appService.cartProducts.results[index].quantity === 1 || this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity) {
      console.log("- button pressed!")
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(this.configs.title==='Deera' || this.configs.title==='LinenRaj'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        this.loadExistingCart()
        return
    }
  }
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    
    //   let obj =
    //   {
    //       "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //       "user_id": ORG_DETAILS.USER_ID,
    //       "extras": {
    //           "find": {
    //             "product_id": Number(tempArray[index].product_id),
    //             "product_cost": Number(tempArray[index].cost),
    //             "quantity": Number(tempArray[index].quantity)
    //           }
    //       }
    //     }
    
    //   this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
    //     resp => {
    //       if (resp) {
    //         if (resp.success === 1 && resp.status_code === 200) {
    //           console.log("tempArray[index].quantity"+tempArray[index].product.id);
    //           tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
    //           tempArray[index].product.per_quantity_amount = resp.result.data.per_quantity_amount;
    //           tempArray[index].product.discount_amount = resp.result.data.discount_amount;
    //           tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
    //           tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
    //           this.discountValue = Number(
    //             resp.result.data.discount_applied_to_product_value
    //           );
    //           this.cartService.updateCartItem(this.appService.cartProducts.results);
    //           this.appService.cartProducts.results = tempArray;
    //           this.calculateTotal();
    //           this.cartService.updateCartItem(this.appService.cartProducts.results);
    //           if(this.CONFIG.name==='Deera'){
    //             let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    //             this.localService.set("cart", cartItemsString);
    //             }
    //           this.loadExistingCart()	
    //         }
    //         else if (resp.success === 0) {
    //           if (resp.message) {
    //             //this.alertService.error(resp.message, this.options);
    //           }
    //         }
    //         else if (resp.message && resp.status_code !== 200) {
    //           //this.alertService.error(resp.message, this.options);
    
    //         }
    //         else {
    //           //this.alertService.error('Something bad happened. Please try again!', this.options);
    //         }
    //       }
    //     },
    //     err => {
    //       if (err.error.statusCode === 403) {
    //         //this.alertService.error('Session Time Out! Please login Again', this.options)
    
    //       }
    //       else if (err.error.error.message) {
    //         //this.alertService.error(err.error.error.message, this.options)
    //       }
    //       else {
    //         //this.alertService.error('Something bad happened. Please try again!', this.options);
    //       }
    //     }
    //   )}
      // if (ORG_DETAILS.NAME === "Vistas"){
      //   this.loaderService.emitLoading();
      //   let userId = this.localService.get('UserId');
      //   let obj = {
      //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
      //     "user_id": ORG_DETAILS.USER_ID,
      //     "extras": {
      //         "find": {
      //           "product_id": Number(tempArray[index].product_id),
      //           "product_cost": Number(tempArray[index].cost),
      //           "quantity": Number(tempArray[index].quantity),
      //         customer_id: Number(userId)
      //       },
      //     },
      //   };
  
      //   try {
      //     this.appService
      //       .postApiCall(obj, ENDPOINTS.STRUCTURED_PROMOTION)
      //       .subscribe(
      //         (resp) => {
      //           if (resp) {
      //             if (resp.success === 1 && resp.status_code === 200) {
      //               this.appService.cartProducts.results[index]["discount_amount"] =
      //             resp.result.data.discount_summary[0].discount_amount;
         
      //         this.appService.cartProducts.results[index][
      //           "after_dicount_price"
      //         ] = resp.result.data.discount_summary[0].after_dicount_price;
      //             } else if (resp.success === 0) {
      //               if (resp.message) {
      //                 //this.alertService.error(resp.message, this.options);
      //               }
      //             } else if (resp.message && resp.status_code !== 200) {
      //               //this.alertService.error(resp.message, this.options);
      //             } else {
      //               //this.alertService.error('Something bad happened. Please try again!', this.options);
      //             }
      //           }
      //         },
      //         (err) => {
      //           if (err.error.statusCode === 403) {
      //             //this.alertService.error('Session Time Out! Please login Again', this.options)
      //           } else if (err.error.error.message) {
      //             //this.alertService.error(err.error.error.message, this.options)
      //           } else {
      //             //this.alertService.error('Something bad happened. Please try again!', this.options);
      //           }
      //         }
      //       );
      //   } catch (err) {
      //     console.log("ERROR IN ADDING ITEM: ", err);
      //   }
      // }
    } 

    if(this.configs.title!=="Deera"){
      this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.configs.title==='LinenRaj'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    this.loadExistingCart()
    }
  }

  increaseItem(index)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    
    if(this.configs.title==='ShubhCards')
    {
        
      tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
    }
    else{
      if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      if(this.configs.title==='BombayHardware')
      {
        //this.alertService.error("Maximum allowed quantity is 1 only",this.options)
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      } else if(this.configs.title==='Deera'){
        // tempArray[index].quantity = 0;
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      else {
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      return
    }
    if(tempArray[index].product.pricing.raw.incrementvalue>0)
    {
      tempArray[index].quantity = (Number(tempArray[index].quantity) + Number(tempArray[index].product.pricing.raw.incrementvalue));
    } else {
      tempArray[index].quantity = (Number(tempArray[index].quantity) + 1);
    }
      // tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    }
  
   if(this.configs.title!=="Deera" ){
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.configs.title==='LinenRaj'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    this.loadExistingCart()
   } 
  }

  navigateToList(category) {
    console.log("insidee11111")
    //this.searchForm.controls['search_term'].setValue(category.name);
  if(this.configs.title === "ShubhCards")
  {
    
    // this.localService.set('is_category_filter','true')
    // this.localService.set('category_filter_name',category.name)

  } 
  if(this.configs.title === "Deera" || this.configs.title === "BestGifts" || this.configs.title === "LinenRaj" || this.configs.title === "Beaubelle"|| this.configs.title === "Kubendran" || this.configs.title === "LushNGreen" || this.configs.title === "LinenRaj" || this.configs.title === "Taazah")
  {
    console.log("insidee222222")
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  if (this.configs.title === 'BombayHardware' || this.configs.title==='AvonSeals'|| this.configs.title==='Vimpro' || this.configs.title=="SpecialWires" || this.configs.title=="Vistas") {
    this.localService.set('allProducts','notall')

    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if(this.configs.title === 'Schift Cosmetics'){
    this.localService.set('allProducts','notall')
    console.log("insideee")
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
   }
   console.log("userId");
   let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience'||this.configs.title === 'Karupattiraja') {
    if(userId){
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      console.log("login check")
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   if (this.configs.title === 'Aiema' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if(this.configs.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  if(this.configs.title === "Mayan"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  else if(this.configs.title !== 'Aiema' && this.configs.title !== 'Cle' && this.configs.title !== 'LinenRaj' && this.configs.title !== 'Beaubelle' && this.configs.title !== 'BombayHardware' && this.configs.title !== 'AvonSeals' && this.configs.title !== 'SpecialWires' && this.configs.title !== 'Vistas' && this.configs.title !== 'BBold' && this.configs.title !== 'Deera' && this.configs.title !== 'BestGifts'&& this.configs.title !== 'Carience'&& this.configs.title !== 'Vimpro'&&this.configs.title!=='Karupattiraja'&& this.configs.title !== 'LinenRaj' && this.configs.title !== 'Babys Pride' && this.configs.title !== 'LushNGreen'  && this.configs.title !== 'Taazah'){
    //console.log("buG")
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  this.searchForm.controls['search_term'].setValue('');
		//this.router.navigate([`/productListing`], { queryParams: { terms: [category.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  

  navigateToList1(category) {
    this.searchForm.controls['search_term'].setValue(category);

  if (this.configs.title === 'BombayHardware' || this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Aiema' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }

	}

  routeTo(){
    document.getElementById("mySidenav").style.width = "0";
  }

  routeTo1(){
    document.getElementById("closebeforecheck").click()
    this.router.navigate(['/checkout-new'])
  }

  routeTo2(){
    document.getElementById("closebeforecheck").click()
    this.router.navigate(['/cart'])
  }
 
  splitRange() {
    console.log("originalRange",this.selectedRange)
    const [start, end] = this.selectedRange.split('-').map(Number);
    this.subRanges = [];
    for (let i = start; i < end; i += 50) {
      const subrangeStart = i;
      const subrangeEnd = i + 50;
      const subrange = `${subrangeStart}-${subrangeEnd}`;
      this.subRanges.push(subrange);
      console.log("subRanges",JSON.stringify(this.subRanges))
    }
  
  }
  productsList(){
    if(this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj' || this.configs.title === 'Babys Pride' || this.configs.title === 'LushNGreen' || this.configs.title === 'Schift Cosmetics'  || this.configs.title === 'Taazah' || this.configs.title === 'SriSenbaga' || this.configs.title === 'HOB'){
      document.getElementById("mySidenav").style.width = "0";
    }
    if(this.configs.title === 'Babys Pride'){
      console.log("this.selectedRange",this.selectedRange)
      this.selectedRange = this.prices1[0] + '-' + this.prices1[1];
      // this.splitRange()
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[this.subRanges],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    }
    else{
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
}

  productsLists(){
    if(this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas' || this.configs.title === 'KatbookStore' || this.configs.title === 'SriSenbaga' || this.configs.title === 'HOB'){
      document.getElementById("mySidenav").style.width = "0";
    }
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }

  productsListkubendran(category){
    if(this.configs.title === 'Deera'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      this.subCategoryData = "default"
    } 
    // else if(this.configs.title === 'PaulCaroline'){
    //   console.log("llllllllllkkkkkk")
    //   this.router.navigate(['/productListing'], {
    //     queryParams: {
    //       terms: [[], [category], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
    //       type: 'combine'
    //     },
    //     relativeTo: this.route,
    //     skipLocationChange: false
    //   }).then(() => {
    //     this.scrollToSection();
    //   });
    // }
    else {
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      // if(this.configs.title=='PaulCaroline'){
      //   console.log("test")
      //   var url1 = document.getElementById("targetSection")
      //   if(url1==null){
      //     console.log("going Paul in if")
      //     setTimeout(() => {
      //       this.delayedFunctionTarget();
      //       }, 500);
 
      //   }
      //   else{
      //     console.log("going Paul in else")
      //     this.delayedFunctionTarget();
 
      //   }
      // }
    }
  
  }
  productsListPaul(category: string) {
    
    this.router.navigate(['/productListing'], {
      queryParams: {
        terms: [[], [category], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        type: 'combine'
      },
      relativeTo: this.route,
      skipLocationChange: false
    }).then(() => {
      this.scrollToSection();
    });
    document.getElementById("mySidenav").style.width = "0";
  }
  productsListHob(category: string) {
    this.router.navigate(['/productListing'], {
      queryParams: {
        terms: [[], [category], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        type: 'combine'
      },
      relativeTo: this.route,
      skipLocationChange: false
    }).then(() => {
      this.scrollToSection();
    });
  }
  productsListHob1(category: string) {
    this.router.navigate(['/productListing'], {
      queryParams: {
        terms: [[], [category], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        type: 'combine'
      },
      relativeTo: this.route,
      skipLocationChange: false
    }).then(() => {
      this.scrollToSection();
    });
  }
  scrollToSection(fragment: string = 'targetSection') {
    const targetElement = document.getElementById(fragment);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log(`${fragment} not found, retrying...`);
      setTimeout(() => {
        this.scrollToSection(fragment);
      }, 500);
    }
  }
  productsListCat(category){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, fragment: 'targetSection', relativeTo:this.route,  skipLocationChange: false });
  }
  productsListSchift(category){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  
  }
  productsListLush(category){
    document.getElementById("mySidenav").style.width = "0";
    if(this.configs.title==='Deera'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    } else {
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    }
  }
  delayedFunctionTarget(){
    console.log("test for scroll comein")
   document.getElementById("targetSection").scrollIntoView();
   
   
  }
  otherMenu(){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigateByUrl("#/sizechart");

  }
  otherMenu1(){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigate([`/contact-us`]);

  }
  aboutus(){
    document.getElementById("mySidenav").style.width = "0";
   this.router.navigate([`/about-us`]);

  }
  home(){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigateByUrl("#/");

  }
  productsListkubendran1(category){
  if(category.includes('others'))
  {
    document.getElementById(category).style.width = "100%";
  }
  else{
    document.getElementById(category).style.width = "50%";
  }
  document.getElementById("mySidenav").style.width = "0";
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
  }
  homelush(){
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav1").style.width = "0";
    this.router.navigate([`/`])
  }
  productsListkubendran2(category,categoryname){
    if(category.includes('others'))
    {
      document.getElementById(category).style.width = "100%";
    }
    else{
      document.getElementById(category).style.width = "50%";
    }
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav1").style.width = "0";
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[categoryname],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      
    }

  closePrelist(){
    this.searchForm.controls['search_term'].setValue('');
    this.preList = [];
  }     
  cartpopUp(){
    console.log("cartpopUp")
    document.getElementById('cartbutton_ln').click()
  }

  calculateTotal()
  {

    if(this.appService.cartProducts.results.length > 0) {
      if(this.configs.title!=='Deera'){
        this.totalPrice = 0;
        console.log("totalPrice")
        this.appService.cartProducts.results.forEach((obj)=>{
          console.log("obj.quantity"+obj.quantity)
          this.totalPrice += (obj.cost * obj.quantity);
        })
  
      } if(this.configs.title==='Deera'){
        {
          this.totalPrice = 0;
          let i: number = 0;
          this.appService.cartProducts.results.forEach((obj)=>
          {
              // this.totalPrice += (obj.product.discount_value).toFixed(2);
              console.log(obj)
              this.totalPrice += (obj.product.discount_value);
              console.log("TOTAL PRICE: ", this.totalPrice);
              i++
          })
          console.log("TOTAL ITEMS: ", i)
        }
  
      }
    }
    else
    {
      this.totalPrice = 0;
    }
  }

  loadExistingCart()
  {
    this.existingCartProduts= [];
    this.appService.cartProducts.results.forEach(value=>
      {
        this.existingCartProduts.push(value.product_id);
      })
  }


  initialLogout(){
    document.getElementById("linenrajcart").click();
   }

   toggleDropdown(open: boolean) {
    // this.subCategoryData = "";
    this.dropdownOpen = open;
    console.log("this.dropdownOpen"+this.dropdownOpen)
  }

  toggleDropdown1(open: boolean) {
    this.dropdownOpen1 = open;
    console.log("this.dropdownOpen"+this.dropdownOpen)
  }
  toggleDropdownPaul(open: boolean) {
    this.dropdownOpen2 = open;
    console.log("this.dropdownOpen"+this.dropdownOpen2)
  }
  toggleDropdownPaul1(open: boolean) {
    this.dropdownOpen3 = open;
    console.log("this.dropdownOpen"+this.dropdownOpen)
  }
  toggleDropdownHob(open: boolean) {
    this.dropdownOpen4 = open;
    console.log("this.dropdownOpen"+this.dropdownOpen4)
  }
  toggleDropdownHob1(open: boolean) {
    this.dropdownOpen5 = open;
    console.log("this.dropdownOpen"+this.dropdownOpen5)
  }
  toggleSubmenu(open: boolean,subCategoryData) {
    this.activeSubCategory = subCategoryData;
    this.subCategoryData = subCategoryData;
    this.cate = subCategoryData;
    this.submenuOpen = open;
    this.subsubcategory = open;
    this.deeradimension=[];
    console.log("this.submenuOpen"+this.submenuOpen)
    console.log("this.subCategoryData"+this.subCategoryData)

  }

  toggleSubmenu2(open: boolean,subCategoryData) {
    this.subCategoryData = subCategoryData;
    this.cate = subCategoryData;
    this.submenuOpen1 = open;
    this.deeradimension=[];
    console.log("this.submenuOpen"+this.submenuOpen)
    console.log("this.subCategoryData"+this.subCategoryData)

  }

  toggleSubmenu1(open: boolean) {
    // this.subCategoryData = "";
    // this.cate = subCategoryData;
    this.activeSubCategory="";
    this.active2="";
    this.submenuOpen = open;
    console.log("this.submenuOpen"+this.submenuOpen)
    console.log("this.subCategoryData"+this.subCategoryData)
this.subsubcategory = open
  }

  toggleSubmenu3(open: boolean) {
    // this.subCategoryData = "";
    // this.cate = subCategoryData;
    this.active2="";
    this.submenuOpen1 = open;
    console.log("this.submenuOpen"+this.submenuOpen)
    console.log("this.subCategoryData"+this.subCategoryData)

  }

  toggleSubSubmenu(open: boolean) {
    this.subSubmenuOpen = open;
  }
  toggleSriBest() {
    if(config.title === "SriSenbaga"){
      
       this.router.navigate([`/`], { queryParams: { type: 'bestSellerSri' }, relativeTo: this.route, skipLocationChange: false });
 //window.scroll(0,4200)
 // document.getElementById("gallery_sec").scrollIntoView();
  this.showMenuSection("bestSellerSri")
 

       }
      }
      toggleSriBestmob() {
        if(config.title === "SriSenbaga"){
          document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav1").style.width = "0";
           this.router.navigate([`/`], { queryParams: { type: 'bestSellerSri' }, relativeTo: this.route, skipLocationChange: false });
     //window.scroll(0,4200)
     // document.getElementById("gallery_sec").scrollIntoView();
      this.showMenuSection("bestSellerSri")
     
    
           }
          }
          
          toggleNewmob() {
            if(config.title === "HOB"){
              document.getElementById("mySidenav").style.width = "0";
        document.getElementById("mySidenav1").style.width = "0";
               this.router.navigate([`/`], { queryParams: { type: 'newArrival_hob' }, relativeTo: this.route, skipLocationChange: false });
          this.showMenuSectionNew("newArrival_hob")
         
        
               }
              }
  showMenuSection(sectionId) {
 
 console.log("rsateytqrwytqr")
 var url1 = document.getElementById("bestSellerSri")
 if(url1==null){
   setTimeout(() => {
     this.delayedFunction();
     }, 100);

 }
 else{
  console.log("test for scroll comein")
   document.getElementById("bestSellerSri").scrollIntoView();

 }

 
  
 
}
delayedFunction(){
  console.log("test for scroll comein")
 document.getElementById("bestSellerSri").scrollIntoView();


}
add(a,c)
{
  let d=c;
  let b=a;
  return a+(c.quantity*c.cost);
}
showMenuSectionNew(sectionId) {
 
  console.log("rsateytqrwytqr1111111111111")
  var url1 = document.getElementById("newArrival_hob")
  if(url1==null){
    setTimeout(() => {
      this.delayedFunctionNew();
      }, 100);
 
  }
  else{
   console.log("test for scroll comein111111111111")
    document.getElementById("newArrival_hob").scrollIntoView();
 
  }
 
  
   
  
 }
 delayedFunctionNew(){
   console.log("test for scroll comein")
  document.getElementById("newArrival_hob").scrollIntoView();
 
 
 }
toggleauthor() {
  if(config.title === "SriSenbaga"){
    document.getElementById("mySidenav").style.width = "0";
document.getElementById("mySidenav1").style.width = "0";
     this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'authorId' }, relativeTo:this.route,  skipLocationChange: false });
//window.scroll(0,4200)
// document.getElementById("gallery_sec").scrollIntoView();
this.showMenuAuthor("authorId")


     }
    }
showMenuAuthor(sectionId) {

console.log("rsateytqrwytqr")
var url1 = document.getElementById("authorId")
if(url1==null){
setTimeout(() => {
this.delayedFunctionAuthor();
}, 100);

}
else{
document.getElementById("authorId").scrollIntoView();

}




}
delayedFunctionAuthor(){
document.getElementById("authorId").scrollIntoView();


}
  toggleSubSubmenu1(open: boolean) {
    this.subCategoryData="";
    this.subSubmenuOpen1 = open;
  }

  appliedDeeraColor(data){
    this.active2="";
    this.deeracolor=[];
    this.active2=data;
    if(this.configs.title=== 'Deera'){
      console.log("facettttttttttt"+data)
      this.deeracolor.push(data)
      // this.callListingScreen()
    }
    this.deeradimension=[];
  }

  appliedDeeraColor1(data){
    // this.deeracolor=[];
  }

  appliedDeeraDimension(data1){
    this.deeradimension=[];
    if(this.configs.title=== 'Deera'){
      console.log("facettttttttttt"+data1)
      this.deeradimension.push(data1)
      // this.callListingScreen()
      console.log("nbnbnb",JSON.stringify(this.cate))
      console.log("nbnbnb",JSON.stringify(this.deeracolor))
      console.log("nbnbnb",JSON.stringify(this.deeradimension))
      this.callListingScreen();
      // this.deeradimension=[];
    //   this.subCategoryData = "";
    // this.cate = "";
    }
  }

  mouseOutDimension() {
    console.log("fffffffffff")
    this.subCategoryData="";
  }

  appliedCategoryRedirection(data){
    this.cate = data;
    // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[this.cate],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    this.callListingScreen();
    this.deeracolor=[];
    this.subCategoryData = "default";
    this.active2 = "";
  }
 
  deeraColorRedirection(){
    if(this.configs.title=== 'Deera'){
      // this.active2="";
      // this.deeracolor=[];
      // this.active2=data;
      // this.deeracolor.push(data)
      console.log("qwqwqw",JSON.stringify(this.cate))
      console.log("qwqwqw",JSON.stringify(this.deeracolor))
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[this.deeracolor],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[this.cate],[],[],[],[],[],[],[],[this.deeracolor],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      this.callListingScreen();
      console.log("rtrt",JSON.stringify(this.cate))
      console.log("rtrt",JSON.stringify(this.deeracolor))
      this.subCategoryData = "default";
      this.active2 = "";
      // this.deeradimension=[];
      //   this.subCategoryData = "";
    // this.cate = "";
    }
  }
  appliedHobCategoryRedirection(data){
    console.log("SubCategory resirect",data)
    this.productsListHob1(data);

  }
  subSubCategoryRedirection(data){
      this.productsListHob(data);
      console.log("SubSub Category",data)
  }
  toggleSubmenusub(open){
    // this.dropdownOpen4 = open;
    this.subsubcategory = open;
  }
  callListingScreen(){
    if(this.configs.title=== 'PaulCaroline'){
      this.router.navigate(['/productListing'], {
        queryParams: {
          terms: [[], [this.cate], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
          type: 'combine'
        },
        relativeTo: this.route,
        skipLocationChange: false
      }).then(() => {
        this.scrollToSection();
      });
    }
    else if(this.configs.title=== 'HOB'){
      this.router.navigate(['/productListing'], {
        queryParams: {
          terms: [[], [this.cate], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
          type: 'combine'
        },
        relativeTo: this.route,
        skipLocationChange: false
      }).then(() => {
        this.scrollToSection();
      });
    }
    else{
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[this.cate],[],[],[],[],[],[],[],[this.deeracolor],[],[],[],[],[],[],[],[],[this.deeradimension],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
    // this.deeradimension=[];
  }

  sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
  
        if (typeof x == "string")
        {
            x = (""+x).toLowerCase(); 
        }
        if (typeof y == "string")
        {
            y = (""+y).toLowerCase();
        }
  
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }
  getCurrentURL () {
    return window.location.href
  }
  toggleNew() {
    if(config.title === "HOB"){
      
       this.router.navigate([`/`], { queryParams: { type: 'newArrival_hob' }, relativeTo: this.route, skipLocationChange: false });
  this.showMenuSectionNewDesk("newArrival_hob")
 

       }
      }
      toggleSale() {
          
           this.router.navigate([`/`], { queryParams: { type: 'sale_Paul' }, relativeTo: this.route, skipLocationChange: false });
      this.showMenuSectionSale("sale_Paul")
      document.getElementById("mySidenav").style.width = "0";
          }
          showMenuSectionNewDesk(sectionId) {
 
            console.log("rsateytqrwytqr1111111111111")
            var url1 = document.getElementById("newArrival_hob")
            if(url1==null){
              setTimeout(() => {
                this.delayedFunctionPaul();
                }, 100);
           
            }
            else{
             console.log("test for scroll comein111111111111")
              document.getElementById("newArrival_hob").scrollIntoView();
           
            }
           
            
             
            
           }
           delayedFunctionNewDesk(){
            console.log("test for scroll comein")
           document.getElementById("newArrival_hob").scrollIntoView();
          
          
          }
         
  showMenuSectionSale(sectionId) {
 
    console.log("rsateytqrwytqr1111111111111")
    var url1 = document.getElementById("sale_Paul")
    if(url1==null){
      setTimeout(() => {
        this.delayedFunctionPaul();
        }, 100);
   
    }
    else{
     console.log("test for scroll comein111111111111")
      document.getElementById("sale_Paul").scrollIntoView();
   
    }
   
    
     
    
   }
   delayedFunctionPaul(){
    console.log("test for scroll comein")
   document.getElementById("sale_Paul").scrollIntoView();
  
  
  }
   toggleProductNew(category) {
    if(config.title === "HOB"){
      document.getElementById("mySidenav").style.width = "0";
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'targetSection' }, relativeTo:this.route,  skipLocationChange: false });
      
  this.showMenuSectionProductNewDesk("targetSection")
 

       }
      }
  showMenuSectionProductNewDesk(sectionId) {
 
    console.log("rsateytqrwytqr1111111111111")
    var url1 = document.getElementById("targetSection")
    if(url1==null){
      setTimeout(() => {
        this.delayedFunctionProductNewDesk();
        }, 100);
   
    }
    else{
     console.log("test for scroll comein111111111111")
      document.getElementById("targetSection").scrollIntoView();
   
    }
   
    
     
    
   }
   delayedFunctionProductNewDesk(){
     console.log("test for scroll comein")
    document.getElementById("newArrival_hob").scrollIntoView();
   
   
   }
  //  calculateSubtotal(products: CartProduct[]) {
  //   this.subTotal = products.reduce((total, item) => {
  //     return total + (item.cost * item.quantity);
  //   }, 0);
  //   console.log("calculated total",this.subTotal)
  // }
}
